<template>
  <ion-page>
    <ion-header>
      <div class="app-max-width">
        <ion-toolbar class="text-center">
        
          <ion-buttons slot="start">
            <ion-button @click.prevent="goBack()">
              <ion-icon :icon="chevronBack" color="medium"></ion-icon>
            </ion-button>
          </ion-buttons>
          <ion-buttons slot="end">
            <ion-button @click.prevent="storeUserGenres()">
              <ion-icon slot="icon-only" :ios="save" :md="save"></ion-icon>
            </ion-button>
          </ion-buttons>

          <ion-title class="text-capitalize">My Genres</ion-title>
        
        </ion-toolbar>
      </div>
    </ion-header>
    <!-- Page Content -->
    <ion-content>

      <div class="app-max-width padding-left-md padding-right-md padding-bottom-md">
        <!-- If Genres -->
        <div v-if="genres.length > 0">
          <ion-item v-for="genre in genres" :key="genre" @click.prevent="updateItems(genre)">
            <div tabindex="0"></div><!-- bug fix for safari 14 -->
            <ion-icon v-if="genre.isChecked === false" :icon="squareOutline" color="dark"></ion-icon>
            <ion-icon v-if="genre.isChecked === true" :icon="checkbox" color="success"></ion-icon> 
            <ion-text class="margin-left-md">{{genre.name}}</ion-text>
          </ion-item>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonContent, IonPage, IonHeader, IonToolbar, IonButtons, IonButton, IonTitle, IonIcon, IonItem, IonText, loadingController, toastController } from '@ionic/vue';
import { defineComponent, onMounted, ref, computed } from 'vue';
import doAuth from '../../services/auth';
import { useStore } from "vuex";
import { useRouter } from 'vue-router';
import { chevronBack, save, checkbox, squareOutline } from 'ionicons/icons';
import apiClient from '../../services/api';

export default defineComponent({
  name: 'ProfileGenresAdmin',
  components: {
    IonContent, IonPage, IonHeader, IonToolbar, IonButtons, IonButton, IonTitle, IonIcon, IonItem, IonText
  },
  data() {
    return {
      isAuth: false
    }
  },
  beforeMount: function() {
    this.isAuth = doAuth()

  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const genres = ref([]);
    const authUser = computed(() => store.state.authUser);
    const timeout = { default: 6000 }
    const toastMessage = ref(null)
    const checkedGenres = ref([]);

    onMounted(() => {
      getUserGenres()
      getGenres()
    })

    async function presentLoading() {
      const loading = await loadingController
        .create({
          cssClass: 'my-custom-class',
          message: 'Please wait...',
          duration: timeout,
        });

      await loading.present();

      setTimeout(function() {
        loading.dismiss()
      }, timeout);
    }

    async function openToast() {
      const toast = await toastController
        .create({
          //message: '',
          duration: 2000,
          message: toastMessage.value,
          position: 'bottom',
          buttons: [
            {
              icon: 'close',
              role: 'cancel',
              handler: () => {
                
              }
            }
          ]
        })
      return toast.present();
    }

    // Get genres from DB
    function getGenres() {
      presentLoading()
      apiClient.get('/api/genres', {
          headers: {
            'Authorization':  `Bearer ${store.state.authToken}`
          }
        }).then(response => {
          let tempGenres = response.data

          if(tempGenres.length > 0) {
            for(var i=0;i<tempGenres.length;i++) {

              genres.value.push({
                id: tempGenres[i].id,
                name: tempGenres[i].name,
                slug: tempGenres[i].slug,
                isChecked: isChecked(tempGenres[i].id)
              })
            }
          }
          
        }).catch(error => {
          toastMessage.value = 'Something went wrong.'
          console.log(error)
          openToast() 
        });
    }

    function getUserGenres() {
      if(authUser.value.genres != null) {
        for(var i=0;i<authUser.value.genres.length;i++) {
          checkedGenres.value.push({
            id: authUser.value.genres[i].id,
            name: authUser.value.genres[i].name,
            slug: authUser.value.genres[i].slug,
          })
        }
      }
    }

    function isChecked(id) {
      var inChecked = false
      if(checkedGenres.value.length > 0) {
        for(var i=0;i<checkedGenres.value.length;i++) {
          if(checkedGenres.value[i].id == id) {
            inChecked = true
          }
        }        
      }
      return inChecked
    }

    function updateItems(item) {
      var checked = false
      // add or remove from store
      if(checkedGenres.value.length > 0) {
        checked = isChecked(item.id)
      }
      // Remove from store
      if(checked === true) {
        if(checkedGenres.value.length > 0) {
          for(var i=0;i<checkedGenres.value.length;i++) {
            if(checkedGenres.value[i].id == item.id) {
              // Remove
              doCheck(checkedGenres.value[i].id, false)
              checkedGenres.value.splice(i, 1)
            }
          }        
        }        
      } 
      // Add to checked
      else {
        //checkedGenres.value = []
        doCheck(item.id, true)
        // Add
        checkedGenres.value.push({
          id: item.id,
          name: item.name,
          slug: item.slug,
        })
      }
    }

    function doCheck(id, val) {
      if(genres.value) {
        for(var i=0;i<genres.value.length;i++) {
          if(genres.value[i].id == id) {
            genres.value[i].isChecked = val
          }
        }
      }
    }

    function storeUserGenres() {
      presentLoading()
      apiClient.post('/api/post/user-genres', 
      {
        genres: checkedGenres.value, 
      },
      {
        headers: {
          'Authorization':  `Bearer ${store.state.authToken}`
        }
      })
      .then(response => {
        // Success
        if(response.data.message == 'success') {
          toastMessage.value = 'You have updated your genres.'
          localStorage.setItem('authUser', JSON.stringify(response.data.user));
          store.commit('setAuthUser', response.data.user);
          goBack()
        }
        // Fail 
        if(response.data.message == 'fail') {
          toastMessage.value = 'There was a problem.'         
        }
        // Fail Validation
        if(response.data.message == 'fail-validation') {
          toastMessage.value = 'There was a problem.'          
        }
        // Fail Auth
        if(response.data.message == 'fail-auth') {
          toastMessage.value = 'You are not authorized to do this.'
        }
        // Fail Auth
        if(response.data.message == 'fail-slug') {
          toastMessage.value = 'That hashtag is already taken.'
        }
        openToast()
      })
      .catch(error => {
        console.log(error)
      }); 
    }

    function goBack() {
      router.go(-1)
    }

    return {
      store, router, goBack, chevronBack, save, genres, checkedGenres, authUser, updateItems, checkbox, squareOutline, storeUserGenres
    }
  },
});
</script>

<style scoped>

</style>
